<template>
    <div class="CurrentResult allh flex column font14" :style="{width:LangeuageAgent === 'CH' ? '1.2rem' : '1.5rem'}">
        <p v-for="(item,index) in ResultWords" :key="index" :class="SetClass(item)" class="flex content-between">
            <font :class="[LangeuageAgent !== 'CH' && 'font12']">
                {{Trans(item,index)}}
            </font>
            <font>{{CurrroadList[index]}}</font>
        </p>
        <p v-if="ResultAskRoadWords.length !== 0" @click="ChangeAskRoad('z')"
           class="flex1 flex items-center colorz pointer">
            <span :class="[LangeuageAgent !== 'CH' && 'font12']">{{ResultAskRoadWords && Lang[ResultAskRoadWords[0]]}}</span>
            <span v-for="(item,index) in AskRoad" :key="index" class="flex content-center items-center flex1">
        <img v-if="AskRoadUrl[item] && AskRoadUrl[item][0]"
             :src="'./img/Baccarat/wl'+AskRoadUrl[item][0]['url']+'.png'">
      </span>
        </p>
        <p v-if="ResultAskRoadWords.length !== 0" @click="ChangeAskRoad('x')"
           class="flex1 flex items-center colorx pointer">
            <span :class="[LangeuageAgent !== 'CH' && 'font12']">{{ResultAskRoadWords && Lang[ResultAskRoadWords[1]]}}</span>
            <span v-for="(item,index) in AskRoad" :key="index" class="flex content-center items-center flex1">
        <img v-if="AskRoadUrl[item] && AskRoadUrl[item][1]"
             :src="'./img/Baccarat/wl'+AskRoadUrl[item][1]['url']+'.png'">
      </span>
        </p>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'CurrentResult',
        props: ['TableInfo', 'ChangeAskRoad'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                Words: {
                    'Baccarat': ['ZJ', 'XJ', 'HJ', 'ZD', 'XD', 'ZS'],//'庄', '闲', '和', '庄对', '闲对', '总数'
                    'LonghuLake': ['LHL', 'LHH', 'HJ', 'ZS'],//'龙', '虎', '和', '总数'
                    'Cattle': ['XJ1', 'XJ2', 'XJ3', 'ZS'],//'闲1', '闲2', '闲3', '总数'
                    'SingleDouble': ['DSD', 'DSS', 'ZS'],//'单', '双', '总数'
                    'SameSize': ['DXTD', 'DXTX', 'DXTT', 'ZS'],//'大', '小', '同', '总数'
                    'ThreeCounsellors': ['XJ1', 'XJ2', 'XJ3', 'ZS'],//'闲1', '闲2', '闲3', '总数'
                    'PushBobbin': ['TTZT1', 'TTZT2', 'TTZT3', 'TTZT4', 'ZS'],//'筒1', '筒2', '筒3', '筒4', '总数'
                },
                ResultWords: [],
                AskRoadWords: {
                    'Baccarat': ['ZWL', 'XWL'],//'庄问路', '闲问路'
                    'LonghuLake': ['LWL', 'HWL'],//'龙问路', '虎问路'
                    'Cattle': [],
                    'SingleDouble': [],
                    'SameSize': ['DXDWL', 'DXXWL'],//'大问路', '小问路'
                    'ThreeCounsellors': [],
                    'PushBobbin': [],
                },
                ResultAskRoadWords: [],
                AskRoad: ['PathRoad', 'YueyouRoad', 'ObliqueRoad'],
                AskRoadUrl: [],
                CurrroadList: [],
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            '$route': {
                handler() {
                    this.ChangeAskRoad(false);
                }
            },
            'TableInfo': {
                handler(n, o) {
                    if (this.ToString(n) === this.ToString(o)) return;
                    // let _Words = this.Words[localStorage.type];
                    // _Words.forEach((item, index) => {
                    //     let Tran = this.Lang[item];
                    //     // if (item === 'ZD') Tran = `${this.Lang['ZD']}`;
                    //     item = _Words.splice(index, 1, Tran);
                    // });
                    this.ResultWords = this.Words[localStorage.type];
                    this.ResultAskRoadWords = this.AskRoadWords[localStorage.type];
                    if (localStorage.type === 'Cattle' || localStorage.type === 'ThreeCounsellors') { //牛牛、三公单独拿出来
                        let dataarr = [0, 0, 0, 0];
                        let arr = this.Clone(n.transForm);
                        if (arr instanceof Array) {
                            let fn = (obj, index) => {
                                if (obj < 0) return;
                                dataarr[index] += 1;
                                dataarr[3] += 1;
                            };
                            arr.forEach((item, index) => {
                                let [a, b, c, d] = item;
                                fn(b, 0);
                                fn(c, 1);
                                fn(d, 2);
                            });
                            this.CurrroadList = this.Clone(dataarr);
                        }
                    } else if (localStorage.type === 'PushBobbin') {  //推筒子
                        let dataarr = [0, 0, 0, 0, 0];
                        let arr = this.Clone(n.transForm);
                        if (arr instanceof Array) {
                            let fn = (obj, index) => {
                                if (obj < 0) return;
                                dataarr[index] += 1;
                                dataarr[4] += 1;
                            };
                            arr.forEach((item, index) => {
                                let [a, b, c, d, e] = item;
                                fn(b, 0);
                                fn(c, 1);
                                fn(d, 2);
                                fn(e, 3);
                            });
                            this.CurrroadList = this.Clone(dataarr);
                        }
                    } else {
                        if (n.transForm.constructor === Object) {
                            this.CurrroadList = this.Clone(n.transForm.resultNumber);
                            this.AskRoadUrl = this.Clone(n.transForm.AskRoad);
                            this.CurrroadList.push((this.CurrroadList).reduce((prev, next, index, array) => prev + next));
                            if (localStorage.type === 'Baccarat') { //如果是百家乐不需要统计庄对闲对
                                this.CurrroadList[5] = this.CurrroadList[5] - this.CurrroadList[4] - this.CurrroadList[3];
                            }
                            if (localStorage.type === 'SingleDouble') {   //单双需要调整单双顺序
                                let Num = this.CurrroadList[0];
                                this.CurrroadList[0] = this.CurrroadList[1];
                                this.CurrroadList[1] = Num;
                            }
                            this.CurrroadList[this.ResultWords.length - 1] = this.CurrroadList[5];  //用于赋值单双这种没5种数量的
                            this.ChangeAskRoad(false);
                        }
                    }
                }, deep: true
            }
        },
        methods: {
            SetClass(str) {
                let name = '';
                //str === '庄' || str === '庄对' || str === '龙' || str === '双' || str === '大'
                if (str === 'ZJ' || str === 'ZD' || str === 'LHL' || str === 'DSS' || str === 'DXTD') name = 'colorz';
                //str === '闲' || str === '闲对' || str === '虎' || str === '单' || str === '小'
                if (str === 'XJ' || str === 'XD' || str === 'LHH' || str === 'DSD' || str === 'DXTX') name = 'colorx';
                //str.indexOf('筒') >= 0
                if (str.indexOf('TTZT') >= 0) name = 'colort';
                //str === '和' || str === '同'
                if (str === 'HJ' || str === 'DXTT') name = 'colorh';
                //str === '总数'
                if (str === 'ZS') name = 'colorzs';
                //str === '闲1' || str === '闲2' || str === '闲3'
                if (str === 'XJ1' || str === 'XJ2' || str === 'XJ3') name = 'colordxs';
                if (localStorage.type === 'Cattle' || localStorage.type === 'SingleDouble' || localStorage.type === 'ThreeCounsellors' || localStorage.type === 'PushBobbin') name += ' flex1 items-center';
                return name;
            },
            Trans(item, index) {
                let Str = this.Lang[item];
                if (item.indexOf('XJ') >= 0) {
                    if (item !== 'XJ') Str = this.Lang['XJ'] + (index + 1);
                }
                if (item.indexOf('TTZT') >= 0) {
                    Str = this.Lang['TTZT'] + (index + 1);
                }
                return Str;
            }
        }
    }
</script>

<style lang="less">
    .CurrentResult {
        width: 1.2rem;
        border-left: 1px solid #000;
        border-right: 1px solid #000;

        p {
            border-bottom: 1px solid #8e8d8a;
            padding: 0 .1rem;
        }

        > p:not(.flex1) {
            line-height: .24rem;
        }

        img {
            width: .14rem;
            height: .14rem;
            border-radius: 50%;
        }
    }
</style>
