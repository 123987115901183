<template>
    <div :class="{'hasbg':Hasbg,'relative':Hasbg,'absolute':!Hasbg}" class="Bet">
        <components ref="CurrentTable" class="CurrentTable" :class="{'Opacity1':Opacity1}" :is="CurrentIs"
                    :TableInfo="TableInfo" :SetAudio="SetAudio"
                    :SetBetState="SetBetState" :CloseBetState="CloseBetState"></components>
        <BetStateCom :TableInfo="TableInfo"></BetStateCom>
        <BetLeftTools></BetLeftTools>
        <!--    &lt;!&ndash;    <BetOnTable :TableInfo="TableInfo"></BetOnTable>&ndash;&gt;-->
        <BetOperation :BetControl="BetControl"></BetOperation>
        <BetNotice :BetState="BetState" :Profit="Profit"></BetNotice>
        <Chip :ShowChip="ShowChip"></Chip>
        <SetChip v-show="ShowChipPond" :ShowChip="ShowChip" :ShowChipPond="ShowChipPond" :MaxNum="6"></SetChip>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import BetLeftTools from '../BetTools/BetLeftTools';
    import BetStateCom from '../BetTools/BetState';
    import BetOperation from '../BetTools/BetOperation';
    // import BetOnTable from '../BetTools/BetOnTable';
    import BetNotice from '../BetTools/BetNotice';
    import Chip from '../BetTools/Chip';
    import SetChip from '../SetIng/SetChip';
    import Baccarat from './Baccarat';
    import LonghuLake from './LonghuLake';
    import Cattle from './Cattle';
    import SingleDouble from './SingleDouble';
    import SameSize from './SameSize';
    import ThreeCounsellors from './ThreeCounsellors';
    import PushBobbin from './PushBobbin';

    export default {
        name: 'index',
        props: ['TableInfo'],
        components: {
            BetLeftTools,
            BetOperation,
            BetStateCom,
            //   BetOnTable,
            BetNotice,
            Chip,
            SetChip,
            Baccarat,
            LonghuLake,
            Cattle,
            SingleDouble,
            SameSize,
            ThreeCounsellors,
            PushBobbin,
        },
        computed: {...mapState(['UserInfo'])},
        data() {
            return {
                BetState: '',  //0 1 表示下注成功和最低限红  2开始下注 3停止下注   4盈亏通知
                Profit: 0,
                Timer1: null,
                Hasbg: true,  //是否有绿色背景
                ShowChipPond: false,
                CurrentIs: '',
                Opacity1: false
            }
        },
        created() {
            this.$nextTick(() => {
                this.CurrentIs = localStorage.type;
                this.SetOpacity();
            });
        },
        watch: {
            '$route'() {
                this.BetState = ''; //关闭所有弹框
                this.CurrentIs = localStorage.type;
                this.BetControl(3); //清空桌面
                this.SetOpacity();
            },
            'TableInfo': {
                handler(n, o) {
                    this.Hasbg = (localStorage.videoSize === 'small');
                    if (n.gameStatus !== o.gameStatus) {
                        if (n.gameStatus === 1) {
                            this.SetBetState(2); //开始下注
                            this.SetAudio('开始下注');
                            this.CloseBetState('');
                        }
                        if (n.gameStatus === 2) { //停止下注
                            this.SetBetState(3); //停止下注
                            this.SetAudio('停止下注');
                            this.CloseBetState('');
                            // if(this.copyBetMoney.filter(item=>item>0).length === 0) this.setBetState();  //本局未下注
                            this.BetControl(1); //清除虚假下注金额
                        }
                        if (n.gameStatus !== 2) this.BetControl(3);  //只要不是开牌中  都清桌面
                        if (n.gameStatus === 3) { //播报结果语音
                            this.BetControl(4, n.roadList[n.roadList.length - 1]);
                        }
                    }
                    if (n.hasOwnProperty('profit')) { //盈亏通知
                        this.Profit = Number(n.profit.toFixed(2));
                        this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: n.balance}})}); //更新余额
                        this.SetBetState(4);
                        this.CloseBetState('');
                    }
                }, deep: true
            }
        },
        methods: {
            BetControl(index, n) {
                let CurrentTable = this.$refs['CurrentTable'];
                if (index === 0) CurrentTable.ConfirmBet(); //确认下注
                if (index === 1) CurrentTable.CancelBet();  //取消下注
                if (index === 2) CurrentTable.RevokeBet();  //撤销下注
                if (index === 3) CurrentTable.ClearTable();  //清空桌面
                if (index === 4) CurrentTable.PlayResult(n);  //播放结果语言
            },
            SetAudio(url) {
                this.$emit('SetAudio', url);
            },
            SetBetState(val) {
                clearTimeout(this.Timer1);
                this.BetState = val;
            },
            CloseBetState() {
                this.Timer1 = setTimeout(() => {
                    this.SetBetState('');
                }, 2000);
            },
            ShowChip() {
                this.ShowChipPond = !this.ShowChipPond;
            },
            SetOpacity() {
                this.Opacity1 = false;
                setTimeout(() => {
                    this.Opacity1 = true;
                }, 300);
            }
        }
    }
</script>

<style lang="less">
    .Opacity1 {
        opacity: 1 !important;
        transition: .3s;
    }

    .Bet {
        /*height: 25%;*/
        z-index: 10;
        height: 2.6rem;

        .CurrentTable {
            opacity: 0;
        }

        &.hasbg {
            background: url("../../../public/img/Common/taimian.png") no-repeat top center/cover;
            height: 2.6rem;
        }

        &.absolute {
            bottom: 0;
            left: 2px;
            right: 2px;
        }
    }
</style>
