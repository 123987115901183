<template>
    <div class="SmallPanel flex">
        <div v-for="(item,index) in TableInfoMb" :key="index" class="flex1 flex column">
            <img :src="'./img/Common/' + item.title + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
            <p v-for="(l,i) in item.children" :key="i" class="mat5 font22 allw flex items-center">
                <span :class="[LangeuageAgent !== 'CH' && 'font12 andsoon']" :title="l"
                      :style="{'width':item.w + 'rem'}">{{l}}</span>：
                <font :title="index === 0 && LotteryPool && (LotteryPool[i] === 0 ? '0' : LotteryPool[i]) || index === 1 && TableCount[i]  || index === 2 && UserInfo[item.key[i]]"
                      class="font18">
                    {{index === 0 && LotteryPool && (LotteryPool[i] === 0 ? "0" :LotteryPool[i] ) || index === 1 &&
                    TableCount[i] || index === 2 && UserInfo[item.key[i]]}}
                </font>
            </p>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'SmallPanel',
        props: ['TableInfo'],
        computed: {
            ...mapState(['UserInfo', 'LangeuageAgent'])
        },
        data() {
            return {
                TableInfoPond: {
                    'Baccarat': [
                        {title: 'xz_cc_jscc', children: ['ZJ', 'XJ', 'HJ', 'ZD', 'XD'], w: 0.45},//'庄', '闲', '和', '庄对', '闲对'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'LonghuLake': [
                        {title: 'xz_cc_jscc', children: ['LHL', 'LHH', 'HJ'], w: 0.45},//'龙', '虎', '和'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'Cattle': [
                        {title: 'xz_cc_jscc', children: ['XJ', 'XJ', 'XJ'], w: 0.45},//'闲1', '闲2', '闲3'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'ThreeCounsellors': [
                        {title: 'xz_cc_jscc', children: ['XJ', 'XJ', 'XJ'], w: 0.45},//'闲1', '闲2', '闲3'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'SingleDouble': [
                        {title: 'xz_cc_jscc', children: ['DSD', 'DSS'], w: 0.45},//'单', '双'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'SameSize': [
                        {title: 'xz_cc_jscc', children: ['DXTD', 'DXTX', 'DXTT'], w: 0.45},//'大', '小', '同'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                    'PushBobbin': [
                        {title: 'xz_cc_jscc', children: ['TTZT', 'TTZT', 'TTZT', 'TTZT'], w: 0.45},//'筒1', '筒2', '筒3', '筒4'
                        {title: 'xz_cc_tmxx', children: ['TH', 'XS', 'JS', 'SX', 'XX'], w: 0.45},//'台号', '靴数', '局数', '上限', '下限'
                        {title: 'xz_cc_yhxx', children: ['YHM', 'YE'], w: 0.7, key: ['username', 'balance']}//'用户名', '余额'
                    ],
                },
                TableInfoMb: [],
                LotteryPool: [],
                TableCount: [0, 0, 0, 0, 0],
            }
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    let _TableInfoMb = this.Clone(this.TableInfoPond[localStorage.type]);
                    _TableInfoMb.forEach((item, index) => {
                        item.children.forEach((l, i) => {
                            let _replace = this.Lang[l];
                            if (this.LangeuageAgent !== 'CH') {
                                if (l === 'XJ') _replace = `${this.Lang['XJ']}${i + 1}`;
                                if (l === 'TTZT') _replace = `${this.Lang['TTZT']}${i + 1}`;
                            }
                            item.children.splice(i, 1, _replace);
                        });
                    });
                    this.TableInfoMb = this.Clone(_TableInfoMb);
                    this.LotteryPool = this.Clone(n['lotteryPool']);
                    if (localStorage.type === 'Cattle' || localStorage.type === 'ThreeCounsellors') {
                        let arr = this.LotteryPool;
                        let _arr = [0, 0, 0];
                        let fn = (a, b, c, d, e) => {
                            if (c >= a && c <= b) _arr[d] += e;
                        };
                        arr.forEach((item, index) => {
                            fn(0, 2, index, 0, item);
                            fn(3, 5, index, 1, item);
                            fn(6, 8, index, 2, item);
                        });
                        this.LotteryPool = this.Clone(_arr);
                    } else if (localStorage.type === 'PushBobbin') {
                        let arr = this.LotteryPool;
                        let _arr = [0, 0, 0, 0];
                        let fn = (a, b, c, d, e) => {
                            if (c >= a && c <= b) _arr[d] += e;
                        };
                        arr.forEach((item, index) => {
                            fn(0, 2, index, 0, item);
                            fn(3, 5, index, 1, item);
                            fn(6, 8, index, 2, item);
                            fn(9, 11, index, 3, item);
                        });
                        this.LotteryPool = this.Clone(_arr);
                    } else if (localStorage.type === 'SingleDouble') {
                        let arr = [this.LotteryPool[1], this.LotteryPool[0]];
                        this.LotteryPool = this.Clone(arr);
                    }
                    this.TableCount = [n.gameName, n.chang, (n.ci === 0 ? '0' : n.ci), (n.limitRed).split('-')[1], (n.limitRed).split('-')[0]];
                }, deep: true, immediate: true
            }
        }
    }
</script>

<style lang="less">
    .SmallPanel {
        background: url("../../../public/img/Common/xz_caichi_bg.png") no-repeat top center/100% 100%;
        padding: .05rem .5rem;

        img {
            width: 1rem;
        }

        p {
            color: #cecece;

            span {
                display: inline-block;
                text-align: justify;
                text-align-last: justify
            }

            font {
                color: #e6d903;
                width: .8rem;
            }
        }
    }
</style>
