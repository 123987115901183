<template>
    <div class="BetOperation flex absolute">
        <div v-for="(item,index) in Operation" :key="index" @click="BetControlFn(index)"
             class="flex items-center content-center pointer">
            <img :src="'./img/Common/'+item.icon+'.png'">
            <span :class="[LangeuageAgent === 'CH' ? 'font20' :'font12 andsoon']" class="colorfff mal5">{{Lang[item.title]}}</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'BetOperation',
        props: ['BetControl'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                Operation: [
                    {title: 'QRXZ', icon: 'xz_qr'},//确认下注
                    {title: 'QXXZ', icon: 'xz_qx'},//取消下注
                    // {title: 'CXXZ', icon: 'xz_gb'}//撤销下注
                ]
            }
        },
        methods: {
            BetControlFn(index) {
                this.BetControl(index);
            }
        }
    }
</script>

<style lang="less">
    .BetOperation {
        z-index: 2;
        bottom: .1rem;
        left: 50%;
        transform: translate(-50%, 0);

        div {
            width: 1.36rem;
            height: .4rem;

            &:nth-child(2) {
                margin: 0 .1rem;
            }

            img {
                width: .27rem;
                height: .27rem;
            }
        }

        div:first-child {
            background: url("../../../public/img/Common/queren_btn.png") no-repeat top center/cover;

            &:hover {
                background: url("../../../public/img/Common/queren_btn_hover.png") no-repeat top center/cover;
            }
        }

        div:nth-child(2) {
            background: url("../../../public/img/Common/quxiao_btn.png") no-repeat top center/cover;

            &:hover {
                background: url("../../../public/img/Common/quxiao_btn_hover.png") no-repeat top center/cover;
            }
        }

        div:last-child {
            background: url("../../../public/img/Common/chexiai_btn.png") no-repeat top center/cover;

            &:hover {
                background: url("../../../public/img/Common/chexiai_btn_hover.png") no-repeat top center/cover;
            }
        }
    }
</style>
