<template>
    <div class="BaccaratType flex1 lz flex">
        <div ref="left" class="left-box relative">
            <ZhuzaiRoad :LeftH="LeftH" :RoadList="RoadList" :IsAskRoad="IsAskRoad"></ZhuzaiRoad>
        </div>
        <div class="flex1 flex column right-box relative">
            <div ref="rightTop" class="right-top relative">
                <MainRoad :RightTopH="RightTopH" :RoadList="RoadList" :IsAskRoad="IsAskRoad"></MainRoad>
            </div>
            <div class="rightBottom flex1 flex">
                <div class="bottomLeft flex column">
                    <div ref="leftBottomTop" class="bottomTop relative flex1">
                        <PathRoad :LeftBottomTop="LeftBottomTop" :RoadList="RoadList" :IsAskRoad="IsAskRoad"></PathRoad>
                    </div>
                    <div ref="leftBottomBottom" class="bottomBottom flex1 relative">
                        <YueyouRoad :LeftBottomBottom="LeftBottomTop" :RoadList="RoadList"
                                    :IsAskRoad="IsAskRoad"></YueyouRoad>
                    </div>
                </div>
                <div class="bottomRight flex1 flex column">
                    <div ref="RightBottomTop" class="bottomTop relative flex1">
                        <ObliqueRoad :RightBottomTop="LeftBottomTop" :RoadList="RoadList"
                                     :IsAskRoad="IsAskRoad"></ObliqueRoad>
                    </div>
                    <div ref="RightBottomBottom" class="bottomBottom relative flex1">
                        <NumRoad :RightBottomBottom="LeftBottomTop" :RoadList="RoadList"
                                 :IsAskRoad="IsAskRoad"></NumRoad>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ZhuzaiRoad from '../Canvas/ZhuzaiRoad';
    import MainRoad from '../Canvas/MainRoad';
    import PathRoad from '../Canvas/PathRoad';
    import YueyouRoad from '../Canvas/YueyouRoad';
    import ObliqueRoad from '../Canvas/ObliqueRoad';
    import NumRoad from '../Canvas/NumRoad';

    export default {
        name: 'BaccaratType',
        props: ['TableInfo', 'IsAskRoad'],
        components: {ZhuzaiRoad, MainRoad, PathRoad, YueyouRoad, ObliqueRoad, NumRoad},
        computed: {
            ...mapState(['whdef'])
        },
        data() {
            return {
                CopyWhdef: 0,   //用于判断当前高度和未来高度是否相等
                RoadList: {},
                LeftH: 0,  //猪仔路高度
                RightTopH: 0,  //大路高度
                LeftBottomTop: 0,  //小路高度  统一采用此高度
                firstIn: true,  //首次进
            }
        },
        created() {
            this.$nextTick(() => {
                this.ComputedHeight();
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (n.change) {
                        this.RoadList = n.transForm;
                    }
                }, deep: true
            },
            'whdef': {
                handler(n, o) {
                    if (n === 0) return;
                    this.CopyWhdef = n;
                    clearTimeout(this.Timer);
                    this.Timer = setTimeout(() => {
                        if (this.whdef === this.CopyWhdef) this.ComputedHeight();
                    }, 1000);
                }
            },
        },
        methods: {
            ComputedHeight() {
                this.LeftH = this.$refs['left'].clientHeight;
                this.RightTopH = this.$refs['rightTop'].clientHeight;
                this.LeftBottomTop = this.$refs['leftBottomTop'].clientHeight;
                // this.LeftBottomBottom = this.$refs['leftBottomBottom'].clientHeight;
                // this.RightBottomTop = this.$refs['RightBottomTop'].clientHeight;
                // this.RightBottomBottom = this.$refs['RightBottomBottom'].clientHeight;
            }
        }
    }
</script>

<style lang="less">
    .BaccaratType {
        .left-box {
            width: 3.4rem;
        }

        .right-box {
            .right-top {
                height: 50%;
            }

            .bottomLeft {
                width: 4.42rem;
            }
        }
    }
</style>
