<template>
    <div class="BigTop relative allw allh">
        <CountDown :TableInfo="TableInfo" v-on="$listeners"></CountDown>
        <BigPanel :TableInfo="TableInfo"></BigPanel>
        <SelfVideo :TableInfo="TableInfo"></SelfVideo>
    </div>
</template>

<script>
    import CountDown from '../TablePanel/CountDown';
    import BigPanel from '../TablePanel/BigPanel';
    import SelfVideo from '../SelfVideo';

    export default {
        name: 'BigTop',
        props: ['TableInfo'],
        components: {CountDown, SelfVideo, BigPanel},//
        watch: {
            'TableInfo': {
                handler(n, o) {
                }, deep: true
            }
        }
    }
</script>

<style lang="less">
    .BigTop {

    }
</style>
