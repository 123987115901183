<template>
    <div class="ThreeCounsellorsType flex1 lz flex">
        <ThreeCounsellorsRoad :RoadList="RoadList" :IsGameIn="true" :DefaultCount="20"></ThreeCounsellorsRoad>
    </div>
</template>

<script>
    import ThreeCounsellorsRoad from '../Canvas/ThreeCounsellorsRoad';
    import {mapState} from 'vuex';

    export default {
        name: 'ThreeCounsellorsType',
        props: ['TableInfo'],
        components: {ThreeCounsellorsRoad},
        computed: {
            ...mapState(['whdef'])
        },
        data() {
            return {
                RoadList: {},
                firstIn: true,  //首次进
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (n.change) {
                        this.RoadList = n.transForm;
                    }
                }, deep: true
            }
        }
    }
</script>

<style lang="less">
    .ThreeCounsellorsType {

    }
</style>
