<template>
    <div class="PushBobbinType flex1 lz flex">
        <PushBobbinRoad :RoadList="RoadList" :IsGameIn="true" :DefaultCount="25"></PushBobbinRoad>
    </div>
</template>

<script>
    import PushBobbinRoad from '../Canvas/PushBobbinRoad';
    import {mapState} from 'vuex';

    export default {
        name: 'PushBobbinType',
        props: ['TableInfo', 'IsAskRoad'],
        components: {PushBobbinRoad},
        computed: {
            ...mapState(['whdef'])
        },
        data() {
            return {
                RoadList: {},
                FirstIn: true,  //首次进
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (n.change) {
                        this.RoadList = n.transForm;
                    }
                }, deep: true
            }
        }
    }
</script>

<style lang="less">
    .PushBobbinType {

    }
</style>
