<template>
    <div ref="BetLonghuLake" class="BetLonghuLake allh allw flex items-center content-center relative">
        <img :class="{'speed':Type === 'big'}" class="relative" usemap="#planetmap"
             :src="'./img/LonghuLake/'+Imgurl + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        <map name="planetmap">
            <area v-for="(item,index) in XY" :key="index" @click="BetClick(index)" @mouseenter="Mousein(index)"
                  @mouseleave="Mousein('')"
                  shape="poly"
                  :coords="item.result" alt="Sun"
                  href="javascript:void(0)">
        </map>
        <div :class="{'speed':Type === 'big'}" class="absolute allh hovermainbox flex content-center">
            <div v-for="(item,index) in ImgArray" :key="index" class="hoverbox absolute">
                <img v-show="CurrentPath === index" :src="'./img/LonghuLake/'+item+'.png'" class="allh">
                <div class="absolute">
                  <span v-show="BetMoney[index].url.length !== 0"
                        :style="{'marginTop': -6*BetMoney[index].url.length*whdef+'px'}"
                        class="absolute font18">{{BetMoney[index].money}}</span>
                    <img v-for="(l,i) in BetMoney[index].url" :key="i" :src="'./img/Common/xz'+l+'.png'"
                         :style="{'marginBottom': i*4*whdef+'px'}" class="absolute allw">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'LonghuLake',
        props: ['TableInfo', 'SetAudio', 'SetBetState', 'CloseBetState'],
        computed: {
            ...mapState(['whdef', 'UserInfo', 'SocketFn', 'DefaultConfig', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CalllConfirmBetFn', 'CalllRevokeBetFn'],
                CurrentPath: '',
                Imgurl: '',
                Type: '',
                XY: [],
                XYConfig: {
                    'big': [
                        {root: [375, 0, 350, 90, 627, 90, 627, 0], result: ''},
                        {root: [627, 0, 627, 90, 900, 90, 872, 0], result: ''},
                        {root: [180, 90, 230, 130, 280, 135, 627, 145, 965, 135, 1105, 130, 1074, 90], result: ''},
                    ],
                    'small': [
                        {root: [453, 0, 435, 118, 770, 118, 770, 0], result: ''},
                        {root: [775, 0, 775, 118, 1103, 118, 1066, 0], result: ''},
                        {root: [213, 118, 255, 146, 360, 165, 790, 178, 1214, 165, 1332, 146, 1319, 118], result: ''},
                    ]
                },
                ImgArray: [],
                Imgconfig: {
                    'big': ['xz_jsdragon_hover', 'xz_jstiger_hover', 'xz_jstie_hover'],
                    'small': ['xz_dragon_hover', 'xz_tiger_hover', 'xz_tie_hover'],
                },
                //下注金额
                BetMoney: [{money: 0, url: []}, {money: 0, url: []}, {money: 0, url: []}],
                //真实下注金额
                CopyBetMoney: [],
                CopyBetMoney1: [],
                FirstIn: true
            }
        },
        created() {
            this.$nextTick(() => {
                this.CopyBetMoney = this.Clone(this.BetMoney);
                this.CopyBetMoney1 = this.Clone(this.BetMoney);
            });
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n == 0) return;
                    this.Copyh = n;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        if (this.whdef == this.Copyh) {
                            this.Init();
                            this.FirstIn = false;
                        }
                    }, this.FirstIn ? 500 : 1000);
                }, immediate: true
            },
            'TableInfo': {
                handler(n, o) {
                    this.XY = this.XYConfig[localStorage.videoSize];
                    this.ImgArray = this.Imgconfig[localStorage.videoSize];
                    if (!this.FirstIn) this.Init();
                    this.Type = localStorage.videoSize;
                    this.Imgurl = localStorage.videoSize === 'small' ? 'xzq_longhu' : 'xzq_jslonghu';
                    if (this.DefaultConfig.LoginTemplate === 'szwjt') {
                        this.Imgurl = localStorage.videoSize === 'small' ? 'xzq_longhu_szwjt' : 'xzq_jslonghu_szwjt';
                    }
                    if (n.hasOwnProperty('betInfo') && n.betInfo !== null) {  //初始化下注信息
                        let array = [0, 1, 2];
                        this.CopyBetMoney.forEach((item, index) => {
                            item.money = n.betInfo[array[index]];
                            item.url = this.CountUrl(item.money);
                        });
                        this.BetMoney = this.Clone(this.CopyBetMoney);
                    }
                }, deep: true
            },
            'UserInfo': {
                handler(n) {
                    let {methods: _methods, data} = n;
                    if (this.CallBack.includes(_methods)) this[_methods](data);
                }, deep: true
            }
        },
        methods: {
            Init() {
                this.$refs['BetLonghuLake'].style.fontSize = "";
                let Em = this.whdef * 100;
                if (localStorage.videoSize === 'big') {
                    let Originally = this.whdef * 1080; //原本的高度
                    let NowHeight = window.innerHeight;
                    Em = (NowHeight / Originally * 100 * this.whdef - 10).toFixed(2);
                    this.$refs['BetLonghuLake'].style.fontSize = Em + 'px';
                }
                this.XY.forEach((item, index) => {
                    item.result = (item.root.map((l, i) => {
                        return l * Em / 100;
                    })).join(',');
                });
            },
            Mousein(val) {
                this.CurrentPath = val;
            },
            BetClick(index) {  //下注效果
                if (this.TableInfo.gameStatus !== 1) return;  //不在投注状态
                let path = this.BetMoney[index];
                path.money += Number(sessionStorage.currentChipOn);
                path.url = this.CountUrl(path.money);
            },
            ConfirmBet() { //确认下注   下注顺序为龙 虎 和
                let gameId = this.$route.query.id;
                let info = this.TableInfo;
                let allBet = 0;
                let betInfo = [0, 0, 0];
                let msg = '';
                if (this.TableInfo.gameStatus !== 1) return; //不在投注状态
                this.BetMoney.forEach((item, index) => {
                    let disparity = item.money - this.CopyBetMoney[index].money;
                    // if (disparity < Number(info.limitRed.split('-')[0]) && disparity !== 0) msg = '投注金额小于最低限红';
                    allBet += disparity;
                    betInfo[index] = disparity;
                });
                if (allBet < Number(info.limitRed.split('-')[0])) msg = '投注金额小于最低限红';
                if (allBet > Number(this.UserInfo.balance)) {
                    this.Toast('2', this.Lang['YEBZ']);//'余额不足'
                    return;
                }
                if (allBet === 0) {
                    this.Toast('2', this.Lang['XYDYL']);//'下注金额必须大于0'
                    return;
                }
                if (msg === '投注金额小于最低限红') {
                    this.SetBetState(1);
                    this.CloseBetState();
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.PlayerBet, 0, {
                    gameId,
                    betInfo
                }], 'CalllConfirmBetFn');
            },
            CalllConfirmBetFn(res) {  //下注成功回调  需要保存真实下注金额
                if (res.status === 1) { //成功的时候
                    this.CopyBetMoney = this.Clone(this.BetMoney);
                    this.SetBetState(0);
                    this.CloseBetState();
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.SetAudio('下注成功');
                }
            },
            RevokeBet() {  //撤销下注
                if (!this.IsPermission()) return;
                let gameId = this.$route.query.id;
                if (this.TableInfo.gameStatus !== 1) {
                    this.Toast('4', this.Lang['BZXZSJ']);//'不在下注时间，不能撤销下注'
                    return;
                }
                if (this.CopyBetMoney.filter(item => item.money > 0).length === 0) {
                    this.Toast('4', this.Lang['HWXZ']);//'当前未下注，不能撤销下注'
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.CancelBet, 0, {
                    gameId
                }], 'CalllRevokeBetFn');
            },
            CalllRevokeBetFn(res) { //撤销下注成功回调
                if (res.status === 1) { //成功的时候
                    let array = [0, 1, 2];
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = res.betInfo[array[index]];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.Toast('1', this.Lang['CXCG']);//'撤销成功'
                    this.SetAudio('撤销下注特效');
                }
            },
            CancelBet() { //取消下注
                this.BetMoney = this.Clone(this.CopyBetMoney);
            },
            ClearTable() { //清空桌面
                this.BetMoney = this.Clone(this.CopyBetMoney1);
                this.CopyBetMoney = this.Clone(this.CopyBetMoney1);
            },
            PlayResult(n) { //播放结果语音
                let audio = '';
                let [a] = n;
                if (a === 0) audio += '龙赢,';
                if (a === 1) audio += '虎赢,';
                if (a === 2) audio += '和局,';
                audio = audio.substr(0, audio.length - 1);
                this.SetAudio(audio);
            }
        }
    }
</script>

<style lang="less">
    .BetLonghuLake {
        > img:first-child {
            width: 15.4em;
            height: 2.4em;
            z-index: 1;
        }

        > img.speed:first-child {
            height: 1.85em;
            width: 12.5em;
            position: absolute;
            bottom: 0;
        }

        .hovermainbox {
            width: 15.4em;
            height: 2.4em;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            pointer-events: none;

            .hoverbox {
                height: 1.18em;
                top: -.02em;

                div {
                    width: .5em;
                    height: .5em;
                    bottom: 0.1em;

                    img {
                        bottom: 0;
                        z-index: 2;
                    }
                }

                span {
                    color: #edcb02;
                    top: .15em;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    transition: .5s;
                }

                &:nth-child(1) {
                    left: 4.3em;
                    width: 3.4em;

                    div {
                        right: .2em;
                    }
                }

                &:nth-child(2) {
                    left: 7.7em;
                    width: 3.46em;

                    div {
                        right: .3em;
                    }
                }

                &:nth-child(3) {
                    width: 11em;
                    height: .6em;
                    top: 1.15em;

                    div {
                        left: 3em;
                    }
                }
            }

            &.speed {
                width: 12.5em;
                height: 1.85em;
                bottom: 0;
                top: auto;
                transform: translate(-50%, 0);

                .hoverbox {
                    height: 0.9em;
                    top: 0em;

                    > img {
                        width: 100%;
                    }

                    div {
                        width: .5em;
                        height: .5em;
                        bottom: 0.1em;

                        img {
                            bottom: 0;
                            z-index: 2;
                        }
                    }

                    span {
                        color: #edcb02;
                        top: .15em;
                        left: 50%;
                        transform: translate(-50%, -100%);
                        transition: .5s;
                    }

                    &:nth-child(1) {
                        left: 3.5em;
                        width: 2.76em;

                        div {
                            right: .2em;
                        }
                    }

                    &:nth-child(2) {
                        left: 6.25em;
                        width: 2.78em;

                        div {
                            right: .3em;
                        }
                    }

                    &:nth-child(3) {
                        height: .45em;
                        width: 8.9em;
                        top: .9em;

                        div {
                            left: 3em;
                        }
                    }
                }
            }
        }
    }
</style>
