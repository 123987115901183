<template>
    <div class="CountDown absolute flex column items-center content-center">
        <div v-show="CopyTableInfo.betTime === 0" class="mab20">
            <img src="../../../public/img/Common/saohou.png">
        </div>
        <span v-show="CopyTableInfo.betTime !== 0" :style="{'color':Fillcolor}" class="mab20 font50">{{CopyTableInfo.betTime}}</span>
        <span class="colorfff font30">{{Lang['QXZ']}}<!--请下注--></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'CountDown',
        props: ['TableInfo'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                RandomId: 'my-player' + parseInt(Math.random() * 100),
                Fillcolor: '#33E11C', // #33E11C   #E3C31D 25   #FF0000 10
                Timer: null,
                CopyTableInfo: {
                    betTime: 0,
                },
                FirstIn: true,
            };
        },
        created() {
            this.$nextTick(() => {
                this.CopyTableInfo = this.Clone(this.TableInfo);
            });
        },
        destroyed() {
            clearTimeout(this.Timer);
        },
        watch: {
            '$route'() {
                this.CopyTableInfo.betTime = 0;
                clearTimeout(this.Timer);
            },
            'TableInfo': {
                handler(n, o) {
                    if (!this.FirstIn) if (n.gameId === o.gameId && n.gameStatus === o.gameStatus && n.betTime === o.betTime) return;
                    this.FirstIn = false;
                    clearTimeout(this.Timer);
                    this.CopyTableInfo = this.Clone(n);
                    if (this.CopyTableInfo.betTime > 0 && this.CopyTableInfo.gameStatus === 1) this.Timer = setInterval(() => {
                        if (this.CopyTableInfo.betTime === 8) this.SetAudio('最后5秒');
                        if (this.CopyTableInfo.betTime <= 6 && this.CopyTableInfo.betTime > 0) this.SetAudio('倒计时铛铛声');
                        this.CopyTableInfo.betTime--;
                        if (this.CopyTableInfo.betTime <= 0) clearTimeout(this.Timer);
                    }, 1000);
                    if (this.CopyTableInfo.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                }, deep: true, immediate: true
            }
        },
        methods: {
            SetAudio(url) {
                this.$emit('SetAudio', url);
            }
        }
    }
</script>

<style lang="less">
    .CountDown {
        width: 1.85rem;
        height: 2rem;
        left: .2rem;
        top: .2rem;
        background: url("../../../public/img/Common/jsbjl_qxz_bg.png") no-repeat top center/cover;
        z-index: 100;

        img {
            width: .65rem;
            height: .65rem;
        }
    }
</style>
