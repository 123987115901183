<template>
    <div class="BetDeposit fixed">
        <span class="colorfff font24">{{Lang['YXYJ']}}<!--押金-->：<font>{{Deposit}}</font></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'BetDeposit',
        props: ['Deposit'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
    }
</script>

<style lang="less">
    .BetDeposit {
        top: 58%;
        right: 0;


        span {
            padding: .07rem .08rem;
            background-color: rgba(0, 0, 0, .6);
        }

        font {
            color: #e3d60e;
        }
    }
</style>
