<template>
    <div class="BetLeftTools absolute">
        <div class="tools flex column">
            <p v-for="(item,index) in tools" :key="index" @click="ToolsControl(index)"
               class="relative flex items-center content-center pointer">
                <img class="absolute pointer allw allh" src="../../../public/img/Common/xz_z_btn_bg.png">
                <font :class="[item.className,LangeuageAgent === 'CH' ? 'font20' :'font12 andsoon flex1']" class="relative">{{Lang[item.title]}}</font>
            </p>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'BetLeftTools',
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                tools: [
                    {title: 'QP', className: 'fullscreen'},//全屏
                    {title: 'SZ', className: 'set'},//设置
                    {title: 'DT', className: 'home'}//大厅
                ],
            }
        },
        methods: {
            ToolsControl(index) {
                if (index === 0) this.HandleFullScreen();
                if (index === 1) this.SetStore({
                    Prop: 'ShowVoice',
                    Val: true
                });
                if (index === 2) this.RouterTo('GameHall');
            }
        }
    }
</script>

<style lang="less">
    .BetLeftTools {
        left: 0;
        bottom: .1rem;

        .tools {
            p {
                width: 0.85rem;
                height: 0.45rem;
                color: #b69a85;

                &:hover {
                    color: #E4C398;
                }

                &:nth-child(2) {
                    margin: 0.08rem 0;
                }

                font {
                    padding-left: .3rem;
                    line-height: .45rem;
                }

                .fullscreen {
                    background: url("../../../public/img/Common/xz_l_qp.png") no-repeat -0.01rem center/.3rem .3rem;

                    &:hover {
                        background: url("../../../public/img/Common/xz_l_qp_hover.png") no-repeat -0.01rem center/.3rem .3rem;
                    }
                }

                .set {
                    background: url("../../../public/img/Common/xz_l_sz.png") no-repeat -0.01rem center/.3rem .3rem;

                    &:hover {
                        background: url("../../../public/img/Common/xz_l_sz_hover.png") no-repeat -0.01rem center/.3rem .3rem;
                    }
                }

                .home {
                    background: url("../../../public/img/Common/xz_l_sy.png") no-repeat -0.01rem center/.3rem .3rem;

                    &:hover {
                        background: url("../../../public/img/Common/xz_l_sy_hover.png") no-repeat -0.01rem center/.3rem .3rem;
                    }
                }
            }
        }
    }
</style>
