<template>
    <div class="GameInTop relative flex column allw allh">
        <div class="flex1">
            <div v-if="TableInfo  && TableInfo.room && TableInfo.room.videoSize === 'small'" class="allw allh small">
                <SmallTop :TableInfo="TableInfo" v-on="$listeners"></SmallTop>
            </div>
            <div v-else-if="TableInfo && TableInfo.room && TableInfo.room.videoSize === 'big'" class="allw allh big">
                <BigTop :TableInfo="TableInfo" v-on="$listeners"></BigTop>
            </div>
        </div>
        <Bet :TableInfo="TableInfo" v-on="$listeners"></Bet>
    </div>
</template>

<script>
    import SmallTop from './SmallTop';
    import Bet from '../Bet';
    import BigTop from './BigTop';


    export default {
        name: 'index',
        props: ['TableInfo'],
        components: {SmallTop, BigTop, Bet},
        data() {
            return {}
        },
        watch: {
            'TableInfo': {
                handler() {

                }, deep: true
            }
        },
        created() {
            this.$nextTick(() => {
            });
        }
    }
</script>

<style lang="less">
    .GameInTop {
        .small {
            /*height: 5.1rem;*/
        }
    }
</style>
