<template>
    <div v-if="OpenerCards.length !== 0" :class="{'speed':TableInfo.room.type === 'big'}"
         class="PushBobbinOpener fixed flex column">
        <div v-for="(item,index) in OpenerName" :key="index" class="flex1 allw flex items-center">
            <div class="imgbox flex1 flex content-center">
                <img :src="'./img/PushBobbin/'+item  + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) + '.png'">
            </div>
            <div class="cardbox allh relative">
                <div class="cardsimgbox allw allh flex items-center content-between">
                    <img v-for="(l,i) in OpenerCards[index+1]" v-show="l!==0" :key="i"
                         :src="'./img/mahjong/'+l+'.png'">
                </div>
                <div class="mb absolute allw flex items-center content-center">
                    <span class="colorfff font24">{{OpenerCattleType[index]}}</span>
                </div>
            </div>
            <span :class="{'opacity0': OpenerPosition !== index}" class="colorfff font28 flex1 text-center">定位</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'PushBobbinOpener',
        props: ['TableInfo', 'SetAudio'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                OpenerName: ['ttz_kp_t1', 'ttz_kp_t2', 'ttz_kp_t3', 'ttz_kp_t4', 'ttz_kp_t5'],
                OpenerPosition: '',  //开牌定位
                OpenerCattleType: ['', '', '', '', ''],
                OpenerCards: []
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (!n.cards) {
                        this.OpenerCards = [];
                        this.OpenerCattleType = ['', '', '', '', ''];
                        return;
                    }
                    this.OpenerCards = this.Clone(n.cards);
                    let [a, b, c, d, e, f] = this.OpenerCards;
                    let _a = [];
                    let _b = [];
                    let _c = [];
                    let _d = [];
                    let _e = [];
                    let _f = [];
                    let ToStringify = (obj) => {
                        return JSON.stringify(obj);
                    };
                    if (o.cards) [_a, _b, _c, _d, _e] = o.cards;
                    if (a[0]) { //定位牌
                        // let serial = {
                        //   '0': '由庄家开始发牌',
                        //   '1': '由闲一开始发牌',
                        //   '2': '由闲二开始发牌',
                        //   '3': '由闲三开始发牌'
                        // };
                        let result = a[0] % 10 % 5;
                        this.OpenerPosition = result; //计算定位牌
                        // if (ToStringify(a) !== ToStringify(_a)) this.SetAudio(serial[result]);
                    }
                    b = b.filter(item => item !== 0);
                    c = c.filter(item => item !== 0);
                    d = d.filter(item => item !== 0);
                    e = e.filter(item => item !== 0);
                    f = f.filter(item => item !== 0);
                    if (b.length === 2) {//庄
                        this.OpenerCattleType[0] = this.CountPushBobbin(b);
                    }
                    if (c.length === 2) {//筒1
                        this.OpenerCattleType[1] = this.CountPushBobbin(c);
                    }
                    if (d.length === 2) {//筒2
                        this.OpenerCattleType[2] = this.CountPushBobbin(d);
                    }
                    if (e.length === 2) {//筒3
                        this.OpenerCattleType[3] = this.CountPushBobbin(e);
                    }
                    if (f.length === 2) {//筒4
                        this.OpenerCattleType[4] = this.CountPushBobbin(f);
                    }
                }, deep: true
            }
        },
        methods: {
            CountPushBobbin(_cards) { //计算推筒子点数
                let NumToUp = ['一', '二', '三', '四', '五', '六', '七', '八', '九'];
                let NeedTrans = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                let [a, b] = _cards;
                if (a === b) {
                    if (this.LangeuageAgent === 'CH') {
                        if (a === 10) return '天对';
                        return '对' + NumToUp[Number(a - 1)];
                    } else {
                        if (a === 10) return '天对';
                        return 'Phải' + NeedTrans[Number(a - 1)];
                    }
                }
                if (a === 10) a = 0.5;
                if (b === 10) b = 0.5;
                return parseInt((a + b) % 10);
            }
        }
    }
</script>

<style lang="less">
    .PushBobbinOpener {
        width: 2.5rem;
        height: 4rem;
        top: 1rem;
        left: 2rem;
        z-index: 10;
        background-color: rgba(0, 0, 0, .6);

        &.speed {
            top: 2.2rem;
        }

        .imgbox img {
            height: .3rem;
        }

        .cardbox {
            width: 1.5rem;

            .cardsimgbox {
                img {
                    height: .8rem;
                }
            }

            .mb {
                height: .35rem;
                bottom: 0;
                background-color: rgba(0, 0, 0, .6);
            }
        }
    }
</style>
