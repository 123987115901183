<template>
    <div :class="[LangeuageAgent === 'CH' ? 'font24' : 'font16']">
        <div v-show="TableInfo.gameStatus !== 0" class="BetState absolute colorfff">
            {{TableInfo.gameStatus === 0 && Lang['XPZ'] || TableInfo.gameStatus === 1 && Lang['XZZ'] || TableInfo.gameStatus === 2
            &&
            Lang['KPZ'] || TableInfo.gameStatus === 3 && Lang['JSZ']}}
        </div>
        <div v-show="TableInfo.ci === 0" class="BetState absolute colorfff">
            {{Lang['XPZ']}}
        </div>
    </div>

</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'BetState',
        computed: {...mapState(['LangeuageAgent'])},
        props: {
            'TableInfo': {
                default() {
                    return {
                        gameStatus: '666'
                    }
                }
            }
        }
    }
</script>

<style lang="less">
    .BetState {
        padding: .13rem 0.02rem;
        left: .2rem;
        top: .2rem;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 0.04rem;
    }
</style>
