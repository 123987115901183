<template>
    <div class="SmallTop allw allh flex">
        <div class="bigVideo relative allh">
            <SelfVideo :TableInfo="TableInfo" :TimeCount="true" v-on="$listeners"></SelfVideo>
        </div>
        <div class="smallVideo mal5 flex column flex1">
            <div class="topVideo flex1 relative overflow-hidden">
                <SelfVideo :TableInfo="TableInfo" :DefaultIndex="1" v-on="$listeners"></SelfVideo>
            </div>
            <div class="mat5">
                <SmallPanel :TableInfo="TableInfo"></SmallPanel>
            </div>
        </div>
    </div>
</template>

<script>
    import SelfVideo from '../SelfVideo';
    import SmallPanel from '../TablePanel/SmallPanel';

    export default {
        name: 'SmallTop',
        props: ['TableInfo'],
        components: {SelfVideo, SmallPanel},
        watch: {
            'TableInfo': {
                handler(n, o) {
                }, deep: true
            }
        }
    }
</script>

<style lang="less">
    .SmallTop {
        .bigVideo {
            width: 10.85rem;
        }

        .smallVideo {
            > div:last-child {
                /*height: 2.4rem;*/
            }
        }
    }
</style>
