<template>
    <div v-if="OpenerCards.length !== 0" :class="{'speed':TableInfo.room.type === 'big'}"
         class="CattleOpener fixed flex column">
        <div v-for="(item,index) in OpenerName" :key="index" class="flex1 allw flex items-center">
            <div class="imgbox flex1 flex content-center">
                <img :src="'./img/Cattle/'+ item + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) +'.png'">
            </div>
            <div class="cardbox allh relative">
                <div class="cardsimgbox allw allh flex items-center content-between">
                    <img v-for="(l,i) in OpenerCards[index+1]" v-show="l!==0" :key="i" :src="'./img/puke/'+l+'.png'">
                </div>
                <div class="mb absolute allw flex items-center content-center">
                    <span class="colorfff font24">{{OpenerCattleType[index]}}</span>
                </div>
            </div>
            <span :class="[OpenerPosition !== index && 'opacity0',LangeuageAgent === 'CH' ? 'font28' : 'font16']"
                  class="colorfff flex1 text-center">{{Lang['DW']}}<!--定位--></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'CattleOpener',
        props: ['TableInfo', 'SetAudio'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                OpenerName: ['kp_01', 'kp_02', 'kp_03', 'kp_04'],
                OpenerPosition: '',  //开牌定位
                OpenerCattleType: ['', '', '', ''],
                OpenerCards: []
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (!n.cards) {
                        this.OpenerCards = [];
                        this.OpenerCattleType = ['', '', '', ''];
                        return;
                    }
                    this.OpenerCards = this.Clone(n.cards);
                    let [a, b, c, d, e] = this.OpenerCards;
                    let _a = [];
                    let _b = [];
                    let _c = [];
                    let _d = [];
                    let _e = [];
                    let ToStringify = (obj) => {
                        return JSON.stringify(obj);
                    };
                    if (o.cards) [_a, _b, _c, _d, _e] = o.cards;
                    if (a[0]) { //定位牌
                        let serial = {
                            '0': '由庄家开始发牌',
                            '1': '由闲一开始发牌',
                            '2': '由闲二开始发牌',
                            '3': '由闲三开始发牌'
                        };
                        let result = ((a[0] % 13) - (Math.ceil((a[0] % 13) / 13) - 1) * 13) % 4;
                        this.OpenerPosition = result; //计算定位牌
                        if (ToStringify(a) !== ToStringify(_a)) this.SetAudio(serial[result]);
                    }
                    let CattleType = {
                        '0': '无牛',
                        '1': '牛1',
                        '2': '牛2',
                        '3': '牛3',
                        '4': '牛4',
                        '5': '牛5',
                        '6': '牛6',
                        '7': '牛7',
                        '8': '牛8',
                        '9': '牛9',
                        '10': '牛牛',
                    };
                    let TransLang = {
                        '0': 'Không ngưu',
                        '1': 'Ngưu 1',
                        '2': 'Ngưu 2',
                        '3': 'Ngưu 3',
                        '4': 'Ngưu 4',
                        '5': 'Ngưu 5',
                        '6': 'Ngưu 6',
                        '7': 'Ngưu 7',
                        '8': 'Ngưu 8',
                        '9': 'Ngưu 9',
                        '10': 'Ngưu ngưu',
                    };
                    b = b.filter(item => item !== 0);
                    c = c.filter(item => item !== 0);
                    d = d.filter(item => item !== 0);
                    e = e.filter(item => item !== 0);
                    if (b.length === 5) {//庄
                        let type = this.CountCattle(b);
                        if (this.LangeuageAgent === 'CH') {
                            this.OpenerCattleType[0] = CattleType[type];
                        } else {
                            this.OpenerCattleType[0] = TransLang[type];
                        }
                        if (ToStringify(b) !== ToStringify(_b) && _b.length !== 0) this.SetAudio(`庄牛,${CattleType[type]}`);
                    }
                    if (c.length === 5) {//闲1
                        let type = this.CountCattle(c);
                        if (this.LangeuageAgent === 'CH') {
                            this.OpenerCattleType[1] = CattleType[type];
                        } else {
                            this.OpenerCattleType[1] = TransLang[type];
                        }
                        if (ToStringify(c) !== ToStringify(_c) && _c.length !== 0) this.SetAudio(`闲一,${CattleType[type]}`);
                    }
                    if (d.length === 5) {//闲2
                        let type = this.CountCattle(d);
                        if (this.LangeuageAgent === 'CH') {
                            this.OpenerCattleType[2] = CattleType[type];
                        } else {
                            this.OpenerCattleType[2] = TransLang[type];
                        }
                        if (ToStringify(d) !== ToStringify(_d) && _d.length !== 0) this.SetAudio(`闲二,${CattleType[type]}`);
                    }
                    if (e.length === 5) {//闲3
                        let type = this.CountCattle(e);
                        if (this.LangeuageAgent === 'CH') {
                            this.OpenerCattleType[3] = CattleType[type];
                        } else {
                            this.OpenerCattleType[3] = TransLang[type];
                        }
                        if (ToStringify(e) !== ToStringify(_e) && _e.length !== 0) this.SetAudio(`闲三,${CattleType[type]}`);
                    }
                }, deep: true
            }
        },
        methods: {
            CountCattle(_cards) { //计算牛型
                let cards = [];
                _cards.forEach((item, index) => {
                    if (item % 13 > 10) {
                        item = 10;
                    } else {
                        item = item % 13;
                    }
                    cards.push(item);
                });
                let lave = 0;
                for (let i = 0; i < cards.length; i++) {
                    lave += cards[i];
                }
                lave = lave % 10;
                for (let j = 0; j < cards.length - 1; j++) {
                    for (let k = j + 1; k < cards.length; k++) {
                        if ((cards[k] + cards[j]) % 10 === lave) {
                            if (lave === 0) {
                                return 10;
                            } else {
                                return lave;
                            }
                        }
                    }
                }
                return 0;
            }
        }
    }
</script>

<style lang="less">
    .CattleOpener {
        width: 4.9rem;
        height: 3.6rem;
        top: 1.5rem;
        left: 2rem;
        z-index: 10;
        background-color: rgba(0, 0, 0, .6);

        &.speed {
            top: 2.2rem;
        }

        .imgbox img {
            height: .3rem;
        }

        .cardbox {
            width: 3.3rem;

            .cardsimgbox {
                img {
                    height: .8rem;
                }
            }

            .mb {
                height: .35rem;
                bottom: 0;
                background-color: rgba(0, 0, 0, .6);
            }
        }
    }
</style>
