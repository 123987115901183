<template>
    <div class="Chip absolute flex items-center">
        <div class="flex content-between chipbox">
            <div v-for="(item,index) in CurrentChip" :key="index" @click="OnChip(index)"
                 :class="{'CurrentChip':CurrentChipOn === index}"
                 class="pointer flex content-center items-center">
                <img :src="'./img/Common/'+item+'.png'">
            </div>
        </div>
        <div @click="ShowChip" class="callChip font16 flex items-center pointer">
            <span class="flex items-center content-between">{{Lang['SZCM']}}<!--设置筹码--></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Chip',
        props: ['ShowChip'],
        computed: {
            ...mapState(['ChangeChip', 'LangeuageAgent'])
        },
        data() {
            return {
                CurrentChip: [],
                CurrentChipOn: 0,
            }
        },
        created() {
            this.$nextTick(() => {
                this.CurrentChip = JSON.parse(localStorage.Chip)[0];
            });
        },
        watch: {
            'CurrentChip': {
                handler(n, o) {
                    if (n.lengeh !== 0) this.OnChip(0);
                }, deep: true
            },
            'ChangeChip': {
                handler(n, o) {
                    if (n === true) {
                        this.CurrentChip = this.ToParse(localStorage.Chip)[0];
                        this.SetStore({
                            Prop: 'ChangeChip',
                            Val: false
                        });
                    }
                }, deep: true
            }
        },
        methods: {
            OnChip(index) {
                this.CurrentChipOn = index;
                sessionStorage.currentChipOn = this['CurrentChip'][index];
            }
        }
    }
</script>

<style lang="less">
    .Chip {
        z-index: 10;
        bottom: .1rem;
        right: 0;

        .chipbox {
            div {
                width: .75rem;
                height: .75rem;
                border-radius: 50%;

                &.CurrentChip {
                    background: url("../../../public/img/Common/cm_bg_hover.png") no-repeat center center/cover;
                }

                &:hover {
                    background: url("../../../public/img/Common/cm_bg_hover.png") no-repeat center center/cover;
                }

                img {
                    width: .55rem;
                    height: .55rem;
                }
            }
        }

        .callChip {
            width: .82rem;
            height: .45rem;
            background: url("../../../public/img/Common/szcm_btn.png") no-repeat center center/100% 100%;
            color: #c29a76;

            &:hover {
                color: #E4C398;

                span {
                    background: url("../../../public/img/Common/szcm_hover.png") no-repeat .1rem center/.3rem;

                }
            }

            span {
                width: 100%;
                height: 100%;
                padding-left: .45rem;
                padding-right: 0.02rem;
                text-align: justify;
                text-align-last: justify;
                background: url("../../../public/img/Common/szcm.png") no-repeat .1rem center/.3rem;
            }
        }
    }
</style>
