<template>
    <div class="GameInBottom flex backgroundWhite">
        <components :is="CurrentComponents" :TableInfo="TableInfo" :IsAskRoad="IsAskRoad"></components>
        <!--            <BaccaratType :tableInfo="tableInfo" :IsAskRoad="IsAskRoad"></BaccaratType>-->
        <CurrentResult :TableInfo="TableInfo" :ChangeAskRoad="ChangeAskRoad"></CurrentResult>
        <!--    <GoodWay :tableInfo="tableInfo"></GoodWay>-->
        <ChatTo></ChatTo>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import BaccaratType from './BaccaratType';
    import CattleType from './CattleType';
    import ThreeCounsellorsType from './ThreeCounsellorsType';
    import PushBobbinType from './PushBobbinType';
    import CurrentResult from './CurrentResult';
    // import GoodWay from './GoodWay';
    import ChatTo from './ChatTo';

    export default {
        name: 'index',
        props: ['TableInfo'],
        components: {BaccaratType, CattleType, ThreeCounsellorsType, PushBobbinType, ChatTo, CurrentResult},//, GoodWay
        computed: {
            ...mapState(['IsAskRoadBool'])
        },
        data() {
            return {
                IsAskRoad: false, //是否问路
                CurrentComponents: ''
            }
        },
        created() {
            this.$nextTick(() => {
                this.init();
            });
        },
        watch: {
            '$route': {
                handler(n, o) {
                    this.init();
                }, deep: true
            },
            'IsAskRoadBool': {
                handler(n, o) {
                    if (!n) {
                        this.IsAskRoad = false;
                        this.SetStore({Prop: 'IsAskRoadBool', Val: true});
                    }
                }, deep: true
            }
        },
        methods: {
            init() {
                let com = {
                    'Baccarat': 'BaccaratType',
                    'LonghuLake': 'BaccaratType',
                    'Cattle': 'CattleType',
                    'SingleDouble': 'BaccaratType',
                    'SameSize': 'BaccaratType',
                    'ThreeCounsellors': 'ThreeCounsellorsType',
                    'PushBobbin': 'PushBobbinType',
                };
                this.CurrentComponents = com[localStorage.type];
            },
            ChangeAskRoad(val) {
                this.IsAskRoad = val;
            },
        }
    }
</script>

<style lang="less">
    .GameInBottom {
        height: 2.05rem;
    }
</style>
