<template>
    <div ref="BetBaccarat" class="BetBaccarat allh allw flex items-center content-center relative">
        <img :class="{'speed':Type === 'big'}" class="relative" usemap="#planetmap"
             :src="'./img/Baccarat/'+Imgurl + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        <map name="planetmap">
            <area v-for="(item,index) in XY" :key="index" @click="BetClick(index)" @mouseenter="Mousein(index)"
                  @mouseleave="Mousein('')"
                  shape="poly"
                  :coords="item.result" alt="Sun"
                  href="javascript:void(0)">
        </map>
        <div :class="{'speed':Type === 'big'}" class="absolute allh hovermainbox flex content-center">
            <div v-for="(item,index) in ImgArray" :key="index" class="hoverbox absolute">
                <img v-show="CurrentPath === index" :src="'./img/Baccarat/'+item+'.png'" class="allh">
                <div class="absolute">
              <span v-show="BetMoney[index].url.length !== 0"
                    :style="{'marginTop': -6*BetMoney[index].url.length*whdef+'px'}"
                    class="absolute font18">{{BetMoney[index].money}}</span>
                    <img v-for="(l,i) in BetMoney[index].url" :key="i" :src="'./img/Common/xz'+l+'.png'"
                         :style="{'marginBottom': i*4*whdef+'px'}" class="absolute allw">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Baccarat',
        props: ['TableInfo', 'SetAudio', 'SetBetState', 'CloseBetState'],
        computed: {
            ...mapState(['whdef', 'UserInfo', 'SocketFn', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CalllConfirmBetFn', 'CalllRevokeBetFn'],
                Copyh: '',
                CurrentPath: '',
                Imgurl: '',
                Type: '',
                XY: [],
                XYConfig: {
                    'big': [{
                        root: [76, 0, 10, 93, 225, 93, 270, 0], result: ''
                    }, {root: [270, 0, 225, 93, 502, 93, 515, 0], result: ''}, {
                        root: [515, 0, 502, 93, 750, 93, 735, 0],
                        result: ''
                    }, {root: [735, 0, 750, 93, 1020, 93, 980, 0], result: ''}, {
                        root: [980, 0, 1020, 93, 1240, 93, 1172, 0],
                        result: ''
                    }],
                    'small': [{
                        root: [83, 0, 10, 118, 278, 118, 326, 0], result: ''
                    }, {root: [326, 0, 278, 118, 622, 118, 635, 0], result: ''}, {
                        root: [635, 0, 622, 118, 925, 118, 910, 0],
                        result: ''
                    }, {root: [910, 0, 925, 118, 1265, 118, 1215, 0], result: ''}, {
                        root: [1215, 0, 1265, 118, 1534, 118, 1460, 0],
                        result: ''
                    }]
                },
                ImgArray: [],
                Imgconfig: {
                    'big': ['jsbjl_xzq_h5', 'jsbjl_xzq_h1', 'jsbjl_xzq_h2', 'jsbjl_xzq_h3', 'jsbjl_xzq_h4'],
                    'small': ['pair_p_hover', 'player_hover', 'tie_hover', 'banker_hover', 'b_pair_hover'],
                },
                //下注金额
                BetMoney: [{money: 0, url: [],}, {money: 0, url: [],}, {money: 0, url: [],}, {money: 0, url: [],}, {
                    money: 0,
                    url: [],
                }],
                //真实下注金额
                CopyBetMoney: [],
                CopyBetMoney1: [],
                FirstIn: true,
            }
        },
        created() {
            this.$nextTick(() => {
                this.CopyBetMoney = this.Clone(this.BetMoney);
                this.CopyBetMoney1 = this.Clone(this.BetMoney);
            });
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n == 0) return;
                    this.Copyh = n;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        if (this.whdef == this.Copyh) {
                            this.Init();
                            this.FirstIn = false;
                        }
                    }, this.FirstIn ? 500 : 1000);
                }, immediate: true
            },
            'TableInfo': {
                handler(n, o) {
                    this.XY = this.XYConfig[localStorage.videoSize];
                    this.ImgArray = this.Imgconfig[localStorage.videoSize];
                    if (!this.FirstIn) this.Init();
                    this.Type = localStorage.videoSize;
                    this.Imgurl = localStorage.videoSize === 'small' ? 'xzq_baijiale' : 'jsbjl_xzq_bg';
                    if (n.hasOwnProperty('betInfo') && n.betInfo !== null) {  //初始化下注信息
                        let array = [4, 1, 2, 0, 3];
                        this.CopyBetMoney.forEach((item, index) => {
                            item.money = n.betInfo[array[index]];
                            item.url = this.CountUrl(item.money);
                        });
                        this.BetMoney = this.Clone(this.CopyBetMoney);
                    }
                }, deep: true
            },
        },
        methods: {
            Init() {
                this.$refs['BetBaccarat'].style.fontSize = "";
                let Em = this.whdef * 100;
                if (localStorage.videoSize === 'big') {
                    let Originally = this.whdef * 1080; //原本的高度
                    let NowHeight = window.innerHeight;
                    Em = (NowHeight / Originally * 100 * this.whdef - 10).toFixed(2);
                    this.$refs['BetBaccarat'].style.fontSize = Em + 'px';
                }
                this.XY.forEach((item, index) => {
                    item.result = (item.root.map((l, i) => {
                        return l * Em / 100;
                    })).join(',');
                });
            },
            Mousein(val) {
                this.CurrentPath = val;
            },
            BetClick(index) {  //下注效果
                if (this.TableInfo.gameStatus !== 1) return;  //不在投注状态
                let path = this.BetMoney[index];
                path.money += Number(sessionStorage.currentChipOn);
                path.url = this.CountUrl(path.money);
            },
            ConfirmBet() { //确认下注   下注顺序为庄 闲 和 庄对 闲对
                let gameId = this.$route.query.id;
                let info = this.TableInfo;
                let allBet = 0;
                let betInfo = [0, 0, 0, 0, 0];
                let msg = '';
                if (this.TableInfo.gameStatus !== 1) return; //不在投注状态
                this.BetMoney.forEach((item, index) => {
                    let disparity = item.money - this.CopyBetMoney[index].money;
                    // if (disparity < Number(info.limitRed.split('-')[0]) && disparity !== 0) msg = '投注金额小于最低限红';
                    allBet += disparity;
                    if (index === 0) betInfo[4] = disparity;
                    if (index === 1) betInfo[1] = disparity;
                    if (index === 2) betInfo[2] = disparity;
                    if (index === 3) betInfo[0] = disparity;
                    if (index === 4) betInfo[3] = disparity;
                });
                if (allBet < Number(info.limitRed.split('-')[0])) msg = '投注金额小于最低限红';
                if (allBet > Number(this.UserInfo.balance)) {
                    this.Toast('2', this.Lang['YEBZ']);//'余额不足'
                    return;
                }
                if (allBet === 0) {
                    this.Toast('2', this.Lang['XYDYL']);//'下注金额必须大于0'
                    return;
                }
                if (msg === '投注金额小于最低限红') {
                    this.SetBetState(1);
                    this.CloseBetState();
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.PlayerBet, 0, {
                    gameId,
                    betInfo
                }], 'CalllConfirmBetFn');
            },
            CalllConfirmBetFn(res) {  //下注成功回调  需要保存真实下注金额
                if (res.status === 1) { //成功的时候
                    this.CopyBetMoney = this.Clone(this.BetMoney);
                    this.SetBetState(0);
                    this.CloseBetState();
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.SetAudio('下注成功');
                }
            },
            RevokeBet() {  //撤销下注
                if (!this.IsPermission()) return;
                let gameId = this.$route.query.id;
                if (this.TableInfo.gameStatus !== 1) {
                    this.Toast('4', this.Lang['BZXZSJ']);//'不在下注时间，不能撤销下注'
                    return;
                }
                if (this.CopyBetMoney.filter(item => item.money > 0).length === 0) {
                    this.Toast('4', this.Lang['HWXZ']);//'当前未下注，不能撤销下注'
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.CancelBet, 0, {
                    gameId
                }], 'CalllRevokeBetFn');
            },
            CalllRevokeBetFn(res) { //撤销下注成功回调
                if (res.status === 1) { //成功的时候
                    let array = [4, 1, 2, 0, 3];
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = res.betInfo[array[index]];
                        item.url = this.CountUrl(item.money);
                    });
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.Toast('1', this.Lang['CXCG']);//'撤销成功'
                    this.SetAudio('撤销下注特效');
                }
            },
            CancelBet() { //取消下注
                this.BetMoney = this.Clone(this.CopyBetMoney);
            },
            ClearTable() { //清空桌面
                this.BetMoney = this.Clone(this.CopyBetMoney1);
                this.CopyBetMoney = this.Clone(this.CopyBetMoney1);
            },
            PlayResult(n) { //播放结果语音
                let audio = '';
                let [a, b, c] = n;
                if (a === 0) audio += '庄赢,';
                if (a === 1) audio += '闲赢,';
                if (a === 2) audio += '和局,';
                if (b === 3) audio += '庄对,';
                if (c === 4) audio += '闲对,';
                audio = audio.substr(0, audio.length - 1);
                this.SetAudio(audio);
            }
        }
    }
</script>

<style lang="less">
    .BetBaccarat {
        > img:first-child {
            width: 15.4em;
            height: 2.4em;
            z-index: 1;
        }

        > img.speed:first-child {
            height: 1.7em;
            width: 12.5em;
            position: absolute;
            bottom: 0;
        }

        .hovermainbox {
            width: 15.4em;
            height: 2.4em;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            pointer-events: none;

            .hoverbox {
                height: 1.2em;
                top: -.02em;

                div {
                    width: .5em;
                    height: .5em;
                    bottom: 0.1em;

                    img {
                        bottom: 0;
                        z-index: 2;
                    }
                }

                span {
                    color: #edcb02;
                    top: .15em;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    transition: .5s;
                }

                &:nth-child(1) {
                    left: .08em;
                    width: 3.16em;

                    div {
                        left: .5em;
                    }
                }

                &:nth-child(2) {
                    left: 2.75em;
                    width: 3.57em;

                    div {
                        left: .55em;
                    }
                }

                &:nth-child(3) {
                    right: 6.18em;
                    width: 3.04em;

                    div {
                        left: .5em;
                    }
                }

                &:nth-child(4) {
                    left: 9.08em;
                    width: 3.53em;

                    div {
                        right: .55em;
                    }
                }

                &:nth-child(5) {
                    right: .1em;
                    width: 3.17em;

                    div {
                        right: .55em;
                    }
                }
            }

            &.speed {
                width: 12.5em;
                height: 1.7em;
                bottom: 0;
                top: auto;
                transform: translate(-50%, 0);

                .hoverbox {
                    height: 0.85em;
                    top: 0em;

                    > img {
                        width: 100%;
                    }

                    div {
                        width: .5em;
                        height: .5em;
                        bottom: 0.1em;

                        img {
                            bottom: 0;
                            z-index: 2;
                        }
                    }

                    span {
                        color: #edcb02;
                        top: .15em;
                        left: 50%;
                        transform: translate(-50%, -100%);
                        transition: .5s;
                    }

                    &:nth-child(1) {
                        left: .08em;
                        width: 2.65em;

                        div {
                            left: .45em;
                        }
                    }

                    &:nth-child(2) {
                        left: 2.25em;
                        width: 2.93em;

                        div {
                            left: .55em;
                        }
                    }

                    &:nth-child(3) {
                        right: 5em;
                        width: 2.5em;

                        div {
                            left: .45em;
                        }
                    }

                    &:nth-child(4) {
                        left: 7.35em;
                        width: 2.9em;

                        div {
                            right: .5em;
                        }
                    }

                    &:nth-child(5) {
                        right: .08em;
                        width: 2.65em;

                        div {
                            right: .4em;
                        }
                    }
                }
            }
        }

        .hoverbox:nth-child(7) {
            left: 13.13em;
            top: 0.07em;
            width: 3.2em;

            div {
                right: .4em;
            }
        }
    }
</style>
