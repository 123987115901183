<template>
    <div class="GameIn fixed allh allw flex">
        <LeftCenter></LeftCenter>
        <div class="flex column flex1">
            <div class="containertop flex1">
                <GameInTop :TableInfo="TableInfo" @SetAudio="SetAudio"></GameInTop>
            </div>
            <div class="containerbottom">
                <GameInBottom :TableInfo="TableInfo" @SetAudio="SetAudio"></GameInBottom>
            </div>
        </div>
        <ChooseTable :ArrowTable="ArrowTable"></ChooseTable>
        <audio ref="audio" :src="QuestionAudio" @ended="AudioEnd"></audio>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import LeftCenter from '@/components/LeftCenter';
    import GameInTop from '@/components/GameInTop';
    import GameInBottom from '@/components/GameInBottom';
    import ChooseTable from '@/components/ChangeTable/ChooseTable';

    export default {
        name: 'index',
        components: {LeftCenter, GameInTop, GameInBottom, ChooseTable},
        computed: {
            ...mapState(['InsideGame', 'SocketFn','LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CallInRoomFn'],
                GameId: null,
                TableInfo: {},
                FirstIn: true, //首次进
                ArrowTable: false  //允许请求大厅资源
            }
        },
        created() {
            this.$nextTick(() => {
                sessionStorage.removeItem('NeedLeave'); //删除多台离开房间印记
                this.InRoom();
            });
        },
        beforeDestroy() {
            clearTimeout(this.timer);
            this.OutRoom();
        },
        watch: {
            '$route'() {  //参数更新进入新房间
                this.FirstIn = true;
                this.OutRoom();
                this.InRoom();
            },
            'InsideGame': {
                handler(n, o) {
                    let copyn = this.Clone(n);
                    let val = this.Clone(n);
                    if (copyn.hasOwnProperty(this.GameId)) {  //表示推送的是当前桌子
                        let _TableInfo = this.Clone(this.TableInfo);
                        if (_TableInfo.hasOwnProperty('profit')) delete _TableInfo['profit']; //消除盈亏
                        if (_TableInfo.hasOwnProperty('betInfo')) delete _TableInfo['betInfo']; //消除历史下注
                        _TableInfo = this.Clone({..._TableInfo, ...copyn[this.GameId]});
                        _TableInfo.change = false;
                        if (copyn[this.GameId].hasOwnProperty('roadList')) {
                            _TableInfo.change = true;
                            _TableInfo.transForm = this.Calculation.road(copyn[this.GameId].roadList);
                        }
                        delete val[this.GameId];
                        this.SetStore({
                            Prop: 'InsideGame',
                            Val: val
                        });
                        this.TableInfo = _TableInfo;
                    }
                }, deep: true
            }
        },
        methods: {
            InRoom() {
                this.GameId = this.$route.query.id;
                this.SocketApi.SendSock([this.GameId, this.cmd.EnterRoom, 0, {'GameId': this.GameId}], 'CallInRoomFn');
            },
            CallInRoomFn(res) {
                let CopyTableInfo = this.Clone(res);
                CopyTableInfo.transForm = this.Calculation.road(CopyTableInfo.roadList);
                CopyTableInfo.room = { //内置一个房间配置信息
                    videoSize: localStorage.videoSize,  //视频大小
                    type: localStorage.videoSize //游戏类型
                };
                if (CopyTableInfo.gameStatus === 1) { //只要不是正在下注 一律播放欢迎光临
                    this.SetAudio('开始下注');
                } else if (this.FirstIn) {
                    let Audiourl = {
                        'Baccarat': '欢迎光临百家乐游戏',
                        'LonghuLake': '欢迎光临龙虎游戏',
                        'Cattle': '欢迎光临牛牛游戏',
                        'SingleDouble': '欢迎光临单双游戏',
                        'SameSize': '欢迎光临大小点游戏',
                        'ThreeCounsellors': '',
                        'PushBobbin': '',
                    };
                    if (Audiourl[localStorage.type]) this.SetAudio(Audiourl[localStorage.type]);
                }
                this.TableInfo = CopyTableInfo;
                this.FirstIn = false;
                this.ArrowTable = true;
            },
            OutRoom() {
                this.SocketApi.SendSock([this.GameId, this.cmd.ExitRoom, 0, {'gameId': this.GameId}]);
            },
            SetAudio(url) {
                this.HandlePlayAudio(url);
            }
        }
    }
</script>

<style lang="less">
    .GameIn {
        min-height: 768px;
        min-width: 1366px;

        .containertop {

        }

        .containerbottom {

        }
    }
</style>
