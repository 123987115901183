<template>
    <div class="CattleType flex1 lz flex">
        <CattleRoad :RoadList="RoadList" :IsGameIn="true" :DefaultCount="20"></CattleRoad>
    </div>
</template>

<script>
    import CattleRoad from '../Canvas/CattleRoad';
    import {mapState} from 'vuex';

    export default {
        name: 'CattleType',
        props: ['TableInfo'],
        components: {CattleRoad},
        computed: {
            ...mapState(['whdef'])
        },
        data() {
            return {
                RoadList: {},
            }
        },
        created() {
            this.$nextTick(() => {
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (n.change) {
                        this.RoadList = n.transForm;
                    }
                }, deep: true
            }
        }
    }
</script>

<style lang="less">
    .CattleType {

    }
</style>
