<template>
    <div class="BetCattle allh allw flex items-center content-center relative">
        <img :class="{'speed':Type === 'big'}" class="relative" usemap="#planetmap"
             :src="'./img/Cattle/'+Imgurl + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        <map name="planetmap">
            <area v-for="(item,index) in XY" :key="index" @click="BetClick(index)" @mouseenter="Mousein(index)"
                  @mouseleave="Mousein('')"
                  shape="poly"
                  :coords="item.result" alt="Sun"
                  href="javascript:void(0)">
        </map>
        <div :class="{'speed':Type === 'big'}" class="absolute hovermainbox flex content-center">
            <div v-for="(item,index) in ImgArray" :key="index" class="hoverbox absolute">
                <img v-show="CurrentPath === index" :src="'./img/Cattle/'+item+'.png'" class="allh allw">
                <div class="absolute">
                    <span v-show="BetMoney[index].url.length !== 0"
                          :style="{'marginTop': -6*BetMoney[index].url.length*whdef+'px'}" class="absolute font18">{{BetMoney[index].money}}</span>
                    <img v-for="(l,i) in BetMoney[index].url" :key="i" :src="'./img/Common/xz'+l+'.png'"
                         :style="{'marginBottom': i*4*whdef+'px'}" class="absolute allw">
                </div>
            </div>
            <div v-for="(item,index) in 3" :class="['IndexNum'+item]" class="IndexNum font30 text-center absolute">
                {{item}}
            </div>
        </div>
        <div v-if="Type === 'big'" class="BetTopVideo fixed">
            <SelfVideo :TableInfo="TableInfo" :DefaultIndex="1" :IsChange="true"></SelfVideo>
        </div>
        <CattleOpener :TableInfo="TableInfo" :SetAudio="SetAudio"></CattleOpener>
        <BetDeposit :Deposit="Deposit"></BetDeposit>
    </div>
</template>

<script>
    import SelfVideo from '../SelfVideo';
    import BetDeposit from '../BetTools/BetDeposit';
    import CattleOpener from '../CardOpener/CattleOpener';
    import {mapState} from 'vuex';

    export default {
        name: 'Cattle',
        props: ['TableInfo', 'SetAudio', 'SetBetState', 'CloseBetState'],
        components: {SelfVideo, BetDeposit, CattleOpener},
        computed: {
            ...mapState(['whdef', 'UserInfo', 'SocketFn', 'LangeuageAgent'])
        },
        data() {
            return {
                CallBack: ['CalllConfirmBetFn', 'CalllRevokeBetFn'],
                Copyh: '',
                Timer: '',
                CurrentPath: '',
                Imgurl: '',
                Type: '',
                XY: [],
                XYConfig: {
                    'big': [
                        {root: [80, 0, 35, 80, 158, 80, 192, 0], result: ''},
                        {root: [192, 0, 158, 80, 285, 80, 310, 0], result: ''},
                        {root: [310, 0, 285, 80, 415, 80, 430, 0], result: ''},
                        {root: [450, 0, 430, 80, 564, 80, 570, 0], result: ''},
                        {root: [570, 0, 564, 80, 688, 80, 683, 0], result: ''},
                        {root: [688, 0, 683, 80, 823, 80, 795, 0], result: ''},
                        {root: [823, 0, 825, 80, 955, 80, 930, 0], result: ''},
                        {root: [930, 0, 955, 80, 1080, 80, 1045, 0], result: ''},
                        {root: [1045, 0, 1080, 80, 1215, 80, 1170, 0], result: ''},
                    ],
                    'small': [
                        {root: [97, 0, 43, 100, 188, 100, 229, 0], result: ''},
                        {root: [229, 0, 188, 100, 334, 100, 364, 0], result: ''},
                        {root: [364, 0, 334, 100, 485, 100, 502, 0], result: ''},
                        {root: [514, 0, 497, 100, 652, 100, 658, 0], result: ''},
                        {root: [658, 0, 652, 100, 799, 100, 792, 0], result: ''},
                        {root: [792, 0, 799, 100, 943, 100, 927, 0], result: ''},
                        {root: [939, 0, 955, 100, 1109, 100, 1079, 0], result: ''},
                        {root: [1079, 0, 1109, 100, 1260, 100, 1220, 0], result: ''},
                        {root: [1220, 0, 1260, 100, 1410, 100, 1350, 0], result: ''},
                    ]
                },
                ImgArray: [],
                Imgconfig: {
                    'big': ['xzq_niuniu_bg1', 'xzq_niuniu_bg3', 'xzq_niuniu_bg2', 'xzq_niuniu_bg4', 'xzq_niuniu_bg6', 'xzq_niuniu_bg5', 'xzq_niuniu_bg7', 'xzq_niuniu_bg9', 'xzq_niuniu_bg8'],
                    'small': ['xzq_niuniu_s_bg1_hover', 'xzq_niuniu_s_bg2_hover', 'xzq_niuniu_s_bg3_hover', 'xzq_niuniu_s_bg4_hover', 'xzq_niuniu_s_bg5_hover', 'xzq_niuniu_s_bg6_hover', 'xzq_niuniu_s_bg7_hover', 'xzq_niuniu_s_bg8_hover', 'xzq_niuniu_s_bg9_hover'],
                },
                //下注金额
                BetMoney: [
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                    {money: 0, url: [],},
                ],
                //真实下注金额
                CopyBetMoney: [],
                CopyBetMoney1: [],
                Deposit: 0,  //押金
                FirstIn: true
            }
        },
        created() {
            this.$nextTick(() => {
                this.CopyBetMoney = this.Clone(this.BetMoney);
                this.CopyBetMoney1 = this.Clone(this.BetMoney);
            });
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n == 0) return;
                    this.Copyh = n;
                    clearTimeout(this.Timer);
                    this.Timer = setTimeout(() => {
                        if (this.whdef == this.Copyh) {
                            this.Init();
                            this.FirstIn = false;
                        }
                    }, this.FirstIn ? 500 : 1000);
                }, immediate: true
            },
            'TableInfo': {
                handler(n, o) {
                    this.XY = this.XYConfig[localStorage.videoSize];
                    this.ImgArray = this.Imgconfig[localStorage.videoSize];
                    if (!this.FirstIn) this.Init();
                    this.Type = localStorage.videoSize;
                    this.Imgurl = localStorage.videoSize === 'small' ? 'xzq_niuniu_s_bg' : 'xzq_niuniu_bg';
                    if (n.hasOwnProperty('betInfo') && n.betInfo !== null) {  //初始化下注信息
                        let array = [1, 0, 2, 4, 3, 5, 7, 6, 8, 9];
                        this.CopyBetMoney.forEach((item, index) => {
                            item.money = n.betInfo[array[index]];
                            item.url = this.CountUrl(item.money);
                        });
                        this.BetMoney = this.Clone(this.CopyBetMoney);
                        this.Deposit = n.betInfo[9];
                    }
                }, deep: true
            },
        },
        methods: {
            Init() {
                this.XY.forEach((item, index) => {
                    item.result = (item.root.map((l, i) => {
                        return l * this.whdef;
                    })).join(',');
                });
            },
            Mousein(val) {
                this.CurrentPath = val;
            },
            BetClick(index) {  //下注效果
                if (this.TableInfo.gameStatus !== 1) return;  //不在投注状态
                let path = this.BetMoney[index];
                path.money += Number(sessionStorage.currentChipOn);
                path.url = this.CountUrl(path.money);
            },
            ConfirmBet() { //确认下注   下注顺序为闲1 平翻多  闲2 平翻多  闲3  平翻多
                let gameId = this.$route.query.id;
                let info = this.TableInfo;
                let allBet = 0;
                let betInfo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let msg = '';
                if (this.TableInfo.gameStatus !== 1) return; //不在投注状态
                this.BetMoney.forEach((item, index) => {
                    let disparity = item.money - this.CopyBetMoney[index].money;
                    // if (disparity < Number(info.limitRed.split('-')[0]) && disparity !== 0) msg = '投注金额小于最低限红';
                    allBet += disparity;
                    if (index === 0) {
                        betInfo[1] = disparity;
                    } else if (index === 1) {
                        betInfo[0] = disparity;
                    } else if (index === 3) {
                        betInfo[4] = disparity;
                    } else if (index === 4) {
                        betInfo[3] = disparity;
                    } else if (index === 6) {
                        betInfo[7] = disparity;
                    } else if (index === 7) {
                        betInfo[6] = disparity;
                    } else {
                        betInfo[index] = disparity;
                    }
                });
                if (allBet < Number(info.limitRed.split('-')[0])) msg = '投注金额小于最低限红';
                if (allBet > Number(this.UserInfo.balance)) {
                    this.Toast('2', this.Lang['YEBZ']);//'余额不足'
                    return;
                }
                if (allBet === 0) {
                    this.Toast('2', this.Lang['XYDYL']);//'下注金额必须大于0'
                    return;
                }
                if (msg === '投注金额小于最低限红') {
                    this.SetBetState(1);
                    this.CloseBetState();
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.PlayerBet, 0, {
                    gameId,
                    betInfo
                }], 'CalllConfirmBetFn');
            },
            CalllConfirmBetFn(res) {  //下注成功回调  需要保存真实下注金额
                if (res.status === 1) { //成功的时候
                    this.Deposit = res.betInfo[9];
                    this.CopyBetMoney = this.Clone(this.BetMoney);
                    this.SetBetState(0);
                    this.CloseBetState();
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.SetAudio('下注成功');
                }
            },
            RevokeBet() {  //撤销下注
                if (!this.IsPermission()) return;
                let gameId = this.$route.query.id;
                if (this.TableInfo.gameStatus !== 1) {
                    this.Toast('4', this.Lang['BZXZSJ']);//'不在下注时间，不能撤销下注'
                    return;
                }
                if (this.CopyBetMoney.filter(item => item.money > 0).length === 0) {
                    this.Toast('4', this.Lang['HWXZ']);//'当前未下注，不能撤销下注'
                    return;
                }
                this.SocketApi.SendSock([gameId, this.cmd.CancelBet, 0, {
                    gameId
                }], 'CalllRevokeBetFn');
            },
            CalllRevokeBetFn(res) { //撤销下注成功回调
                if (res.status === 1) { //成功的时候
                    let array = [1, 0, 2, 4, 3, 5, 7, 6, 8, 9];
                    this.CopyBetMoney.forEach((item, index) => {
                        item.money = res.betInfo[array[index]];
                        item.url = this.CountUrl(item.money);
                    });
                    this.Deposit = res.betInfo[9];
                    this.BetMoney = this.Clone(this.CopyBetMoney);
                    this.SetStore({Prop: 'UserInfo', Val: this.Clone({...this.UserInfo, ...{balance: res.balance}})}); //更新余额
                    this.Toast('1', this.Lang['CXCG']);//'撤销成功'
                    this.SetAudio('撤销下注特效');
                }
            },
            CancelBet() { //取消下注
                this.BetMoney = this.Clone(this.CopyBetMoney);
            },
            ClearTable() { //清空桌面
                this.Deposit = 0;
                this.BetMoney = this.Clone(this.CopyBetMoney1);
                this.CopyBetMoney = this.Clone(this.CopyBetMoney1);
            },
            PlayResult(n) { //播放结果语音
                let audio = '';
                let [a, b, c, d] = n;
                if (b > 0) audio += '闲一赢,';
                if (c > 0) audio += '闲二赢,';
                if (d > 0) audio += '闲三赢,';
                if (b > 0 && c > 0 && d > 0) audio = '庄牛通赔,';
                if (b < 0 && c < 0 && d < 0) audio = '庄牛通吃,';
                audio = audio.substr(0, audio.length - 1);
                this.SetAudio(audio);
            }
        }
    }
</script>

<style lang="less">
    .BetCattle {
        > img:first-child {
            width: 14.5rem;
            height: 1.8rem;
            z-index: 1;
        }

        > img.speed:first-child {
            height: 1.4rem;
            width: 12.5rem;
            position: absolute;
            bottom: 0;
        }

        .hovermainbox {
            width: 14.5rem;
            height: 1.8rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            pointer-events: none;

            .IndexNum {
                width: .4rem;
                height: .4rem;
                background-color: #0b531e;
                border: solid 2px #31b860;
                color: #fdfdfd;
                border-radius: 50%;
                line-height: .4rem;
                top: -.2rem;

                &.IndexNum1 {
                    left: .8rem;
                }

                &.IndexNum2 {
                    left: 5.05rem;
                }

                &.IndexNum3 {
                    left: 9.3rem;
                }
            }

            .hoverbox {
                height: 1rem;

                div {
                    width: .5rem;
                    height: .5rem;
                    bottom: 0.1rem;

                    img {
                        bottom: 0;
                        z-index: 2;
                    }
                }

                span {
                    color: #edcb02;
                    top: .15rem;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    transition: .5s;
                }

                &:nth-child(1) {
                    left: .43rem;
                    width: 1.9rem;

                    div {
                        left: .5rem;
                    }
                }

                &:nth-child(2) {
                    left: 1.9rem;
                    width: 1.8rem;

                    div {
                        left: .5rem;
                    }
                }

                &:nth-child(3) {
                    left: 3.38rem;
                    width: 1.66rem;

                    div {
                        right: .5rem;
                    }
                }

                &:nth-child(4) {
                    left: 5.02rem;
                    width: 1.55rem;

                    div {
                        left: .5rem;
                    }
                }

                &:nth-child(5) {
                    left: 6.5rem;
                    width: 1.5rem;

                    div {
                        left: .5rem;
                    }
                }

                &:nth-child(6) {
                    left: 7.93rem;
                    width: 1.55rem;

                    div {
                        right: .5rem;
                    }
                }

                &:nth-child(7) {
                    left: 9.45rem;
                    width: 1.65rem;

                    div {
                        right: .5rem;
                    }
                }

                &:nth-child(8) {
                    left: 10.8rem;
                    width: 1.8rem;

                    div {
                        right: .5rem;
                    }
                }

                &:nth-child(9) {
                    left: 12.18rem;
                    width: 1.85rem;

                    div {
                        right: .5rem;
                    }
                }
            }

            &.speed {
                height: 1.4rem;
                width: 12.5rem;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 0);
                z-index: 10;
                pointer-events: none;
                top: auto;

                .IndexNum {
                    width: .4rem;
                    height: .4rem;
                    background-color: #0b531e;
                    border: solid 2px #31b860;
                    color: #fdfdfd;
                    border-radius: 50%;
                    line-height: .4rem;
                    top: -.2rem;

                    &.IndexNum1 {
                        left: .7rem;
                    }

                    &.IndexNum2 {
                        left: 4.4rem;
                    }

                    &.IndexNum3 {
                        left: 8rem;
                    }
                }

                .hoverbox {
                    height: .75rem;
                    top: .01rem;

                    div {
                        width: .5rem;
                        height: .5rem;
                        bottom: 0.1rem;

                        img {
                            bottom: 0;
                            z-index: 2;
                        }
                    }

                    span {
                        color: #edcb02;
                        top: .15rem;
                        left: 50%;
                        transform: translate(-50%, -100%);
                        transition: .5s;
                    }

                    &:nth-child(1) {
                        left: .38rem;
                        width: 1.65rem;

                        div {
                            left: .5rem;
                        }
                    }

                    &:nth-child(2) {
                        left: 1.63rem;
                        width: 1.6rem;

                        div {
                            left: .55rem;
                        }
                    }

                    &:nth-child(3) {
                        left: 2.93rem;
                        width: 1.45rem;

                        div {
                            left: .5rem;
                        }
                    }

                    &:nth-child(4) {
                        left: 4.35rem;
                        width: 1.32rem;

                        div {
                            right: .55rem;
                        }
                    }

                    &:nth-child(5) {
                        left: 5.6rem;
                        width: 1.3rem;

                        div {
                            right: .55rem;
                        }
                    }

                    &:nth-child(6) {
                        left: 6.83rem;
                        width: 1.33rem;

                        div {
                            right: .55rem;
                        }
                    }

                    &:nth-child(7) {
                        left: 8.14rem;
                        width: 1.43rem;

                        div {
                            right: .55rem;
                        }
                    }

                    &:nth-child(8) {
                        left: 9.31rem;
                        width: 1.53rem;

                        div {
                            right: .55rem;
                        }
                    }

                    &:nth-child(9) {
                        left: 10.49rem;
                        width: 1.61rem;

                        div {
                            right: .55rem;
                        }
                    }
                }
            }
        }

        .BetTopVideo {
            top: .2rem;
            left: 4.3rem;
            width: 4rem;
            height: 2rem;
            background-color: rgba(0, 0, 0, .6);
        }
    }
</style>
