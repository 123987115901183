<template>
    <div class="ChatTo allh relative overflow-hidden">
        <p class="absolute allw font20 NeedAnimation">
            <font>{{Lang['YXGG']}}<!--公告--> :</font>{{Tips}}
        </p>
<!--        <div class="absolute allw flex items-center content-between border-box backgroundWhite">-->
<!--            <input class="flex1 mar5" type="text">-->
<!--            <img class="pointer" :src="'./img/Common/fasong'+(LangeuageAgent === 'CH' ? '' : LangeuageAgent)+'.png'">-->
<!--        </div>-->
<!--        <span class="absolute font20 colorfff allw text-center">{{Lang['ZWKF']}}&lt;!&ndash;暂未开放，敬请期待&ndash;&gt;</span>-->
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'ChatTo',
        computed: {
            ...mapState(['Tips', 'LangeuageAgent'])
        },
    }
</script>

<style lang="less">
    .ChatTo {
        width: 3rem;
        border-left: 1px solid #efefef;

        > p {
            line-height: 1;
            word-break: break-all;
            bottom: -75%;
            overflow: hidden;

            &.NeedAnimation {
                animation-name: NeedAnimation;
                animation-duration: 20s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-delay: 0s;
            }


            font {
                color: red;
            }
        }

        @keyframes NeedAnimation {
            0% {
                bottom: -75%;
            }
            100% {
                bottom: 100%;
            }
        }

        > div, > span {
            bottom: 0;
            height: .5rem;
            z-index: 10;
        }

        > div {
            padding: 0.05rem;

            input {
                width: 2rem;
                height: .4rem;
                line-height: .4rem;
                border-radius: 0.06rem;
                border: solid 1px #9b6a4d;
            }

            img {
                width: .7rem;
                height: .4rem;
            }
        }

        span {
            line-height: .5rem;
            background-color: rgba(0, 0, 0, .7);
        }
    }
</style>
